.hero{
    background-color: #000;
    width: 100%;
    height: 100vh;
}
.hero_video_container{
    width: 100%;
    height: 100%;
    position: absolute;
}

.hero_video_container video{
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    opacity: 0.5;
    background-color: #000;
}

.hero_container{
    line-height: 1.15;
    font-size: 1.6rem;   
    box-sizing: inherit;
    display: flex;
    flex-direction: column;
    width: 55vw;
    margin-left: 7.2rem;
    padding-top: 0;
    height: 100%;
}

.hero_text_container {    
    line-height: 1.15;
    font-size: 1.6rem;
    box-sizing: inherit;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 12.5rem 0;
    justify-content: center;
    color: #fff;
}

.hero_text_container p{    
    color: #fff;
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    margin-bottom: 1.5rem;    
    max-width: 28.8rem;
    font-size: 1.4rem;
    line-height: 2.6rem;
    letter-spacing: .054rem;
    font-family: Helvetica,Arial,sans-serif;
    font-weight: 500;
    z-index: 3;    
}

.hero_text_container h1{
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    z-index: 2;    
    font-size: 4.4rem;
    line-height: 5rem;
    letter-spacing: 0;
    font-family: Helvetica,Arial,sans-serif;
    font-weight: 500;
    max-width: 79.2rem;   
    z-index: 3;   
}

@media screen and (max-width: 1360px){
    .hero_container{
        width: 60vw;
        margin-left: 10vw;
    }
    .hero_text_container h1{
        font-size: 3.0rem;
        line-height: 3.8rem;
    }
    .hero_text_container p{
        font-size: 1.0rem;
        line-height: 1.6rem;
    }
}

@media screen and (max-width: 768px){
    .hero_container{
        width: 80vw;
        margin-left: 10vw;
    }
    .hero_text_container h1{
        font-size: 1.6rem;
        line-height: 2.5rem;
    }
    .hero_text_container p{
        font-size: 0.8rem;
        line-height: 1.6rem;
    }
}