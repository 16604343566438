.anim{
    transition: 0.7s all ease;
}

.anim_opacity{
   
    opacity: 0;    
}

.anim_opacity.active_anim{
    opacity: 1;
}

.anim_translate_y{    
    transform: translateY(-80px);
    opacity: 0; 
}

.anim_translate_y.active_anim{
    opacity: 1;
    transform: translateY(0px);
}